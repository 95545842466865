// Responsible for import any data from excel files or from buffer.
// Once files added shows preview to the user without sending any requests to server.
// User able to remove some rows and also match table columns with it's meaning from select boxes in the header.
// Able to automatch columns if imported table header on first row,
// user able to remove empty first lines to make it happen.
// Automatically apply existing import template, which could be updated or created.
// Once matching is done and user click to submit import sends data to server, see ImportExcel service.
// On success import reloads page or redirect to another url provided by backend.
// Expected data attributes:
// - columns: used to match with excel columns, see all columns in ImportExcel::Columns module.
// - import-templates: list of created and available import templates for this kind of import (document, products, contragents)
// - master-id: optional, when items should be assosiated with one record, like document_id for document items
// Widget use additional data from import template settings form, and send them to backend with all items.

import BaseWidget from './base_widget'
import shortid from 'shortid'
import { range, escapeRegExp, omit, compact, toString } from 'lodash'
import * as xlsx from 'xlsx'
import { formToObject } from 'services/form'
import { getDuplicates } from 'services/array'
import axios from 'axios'
import { onSubmitCreateOption } from 'services/select'
import { isAxiosNetworkError, isAxiosServerError } from 'services/errors'

// + Import templates per document, default preselected, update template, create new. Should be just array of selected column values.
// + Heigh of modal, buttons at the bottom will be unreachable if long sheet.
// + Auto match columns only if no template
// + Start import from field, should colorize row
// + Options like, update or not, create or not
// Send to server and show loader
// Import at backend
// Recalculate line items
// Products import
// Contragents import

class ImportExcelWidget extends BaseWidget {
  MAX_PROGRESS_ERRORS_COUNT = 3600 // 1 hour (if progress track is 1 sec)
  MAX_IMPORT_ROWS = 20000
  MAX_DOC_IMPORT_ROWS = 1000
  MAX_ROWS_TO_DISPLAY = 100

  initialize() {
    this.$table = this.el.find('.import-table')
    this.$drop = this.el.find('.drop-excel-file')
    this.$bufferBtn = this.el.find('.buffer-btn')
    this.$excelFileBox = this.el.find('.excile-file-box')
    this.$excelFileInput = this.el.find('#excel-file')
    this.$importBtn = this.el.find('.submit-btn')
    this.$container = this.el.find('.import-container')
    this.$errors = this.el.find('.import-errors')
    this.$updateTemplateBtn = this.el.find('.update-import-template-btn')
    this.$modal = this.el.closest('.modal')
    this.$importSettings = this.el.find('.import-settings')
    this.$importTemplateModal = $('#import-template-modal')
    this.$importTemplateSelect = this.el.find('.import-template-select')
    this.$startRow = this.$importSettings.find('.start-row-input')
    this.$alert = this.el.find('.alert')
    this.$loader = this.el.find('.import-loader')
    this.table = []
    this.columns = this.prepareColumns()
    this.matchedColumns = []
    this.lock = false // prevent table modification if it's already doing it
    this.importTemplates = [ ...this.data.importTemplates ]
    this.progressErrorsCount = 0
  }

  prepareColumns() {
    return this.data.columns.map(col => {
      if (!col.regex) return col
      return {...col, regex: new RegExp(col.regex, 'i')}
    })
  }

  reload() {
    this.unmount()
  }

  unmount() {
    this.table = []
    this.userManuallySelectedColumn = false
    this.matchedColumns = []
    this.lock = false
    this.progressErrorsCount = 0
    this.blockPerformImport = false

    this.stopTrackProgress()
    this.toggleLoader(false)
    this.$errors.find('.error-description').empty()
    this.$errors.find('.error-messages').empty()
    this.$errors.hide()
    this.hideAlert()
    this.$table.hide()
    this.$table.find('thead, tbody').empty()
    this.$importBtn.hide()
    this.$excelFileInput.val('')
    this.$updateTemplateBtn.hide()
    this.$importSettings.hide()

    this.$drop.show()
    this.$bufferBtn.show()
    this.$excelFileBox.show()
  }

  bindEvents() {
    this.$modal.on('show.bs.modal', this.reload.bind(this))
    this.$modal.on('hide.bs.modal', this.reload.bind(this))
    this.$bufferBtn.click(this.handleBufferImport.bind(this))
    this.$excelFileInput.change(this.handleFileUpload.bind(this))
    this.$drop.on('dragover', false).on('drop', this.handleFileDrop.bind(this))
    this.$importBtn.click(this.performImport.bind(this))
    this.$updateTemplateBtn.click(this.handleUpdateTemplate.bind(this))
    this.$importTemplateSelect.change(this.applySelectedTemplate.bind(this))
    this.$importTemplateModal.find('form').submit(this.onCreateImportTemplate.bind(this))
    this.$startRow.keyup(this.setStartRow.bind(this))
    this.$startRow.change(this.setStartRow.bind(this))
    this.el.find('.close-and-reload-btn').click(this.handlePageReload.bind(this))
  }

  displayPreview(data) {
    this.hideAlert()

    this.table = this.convertExcelDataToJson(data)

    if (!this.table.length) {
      return this.showAlert(window.I18n.t('excel_import_widget.no_import_items'), { error: true })
    }

    this.renderTable(true)
    this.$drop.hide()
    this.$bufferBtn.hide()
    this.$excelFileBox.hide()
    this.$table.show()
    this.$importBtn.show()
    this.$updateTemplateBtn.show()
    this.$importSettings.show()
    this.$importTemplateSelect.trigger('change')
  }

  handlePageReload(e) {
    e.preventDefault()

    window.location.reload()
  }

  // Table body will be rerendered each time when some row removed, but not thead.
  renderTable(initial) {
    if (!this.table.length) return this.reload()

    if (initial) this.$table.find('thead').html(this.htmlTableHead())
    if (initial) this.addOnlyFirstRowsMessage()

    this.$table.find('tbody').html(this.htmlTableBody())

    if (initial) {
      this.$table.find('.column-select').change(this.handleColumnSelect.bind(this))
      this.autoMatchColumns()
    }
    this.setStartRow()
    this.$table.find('.remove-row-btn').click(this.handleRowRemove.bind(this))
  }

  addOnlyFirstRowsMessage() {
    if (this.$table.find('.only-first-rows').length) return

    this.$table.find('.table-box').append(`
      <div class='only-first-rows'>
        ${window.I18n.t('excel_import_widget.only_first_rows', { rows: this.MAX_ROWS_TO_DISPLAY })}
      </div>
    `)
  }

  validate(items) {
    let error
    const columnsCount = this.tableColumnsCount()
    const matchedColumns = compact(this.matchedColumns.slice(0, columnsCount))
    const duplicatedColumns = getDuplicates(matchedColumns)
      .map(dc => {
        const column = this.columns.find(c => c.key === dc)
        return column ? column.title : 'Unknown'
      })

    switch (true) {
      case !matchedColumns.length:
        error = window.I18n.t('excel_import_widget.columns_not_matched')
        break
      case !!duplicatedColumns.length:
        error = window.I18n.t('excel_import_widget.columns_duplicates_matched', {
          duplicated_columns: duplicatedColumns.join(', ')
        })
        break
      case !items.length:
        error = window.I18n.t('excel_import_widget.no_import_items')
        break
      case items.length > this.MAX_IMPORT_ROWS:
        error = window.I18n.t('excel_import_widget.max_rows_exceeded', { max_rows: this.MAX_IMPORT_ROWS })
        break
      case !!this.data.masterId && (items.length + this.data.itemsCount) > this.MAX_DOC_IMPORT_ROWS:
        error = window.I18n.t('excel_import_widget.max_document_rows_exceeded', { max_rows: this.MAX_DOC_IMPORT_ROWS })
    }

    if (error) {
      this.showAlert(error, { error: true })
      return false
    }

    return true
  }

  async performImport() {
    if (this.blockPerformImport) return

    try {
      this.blockPerformImport = true
      this.hideAlert()

      const { allow_create, allow_update, allow_delete, search_by_title,
        kind, group_divider, thousandth_divider } = this.importTemplateParams()
      const items = this.prepareItemsToImport()

      if (!this.validate(items)) {
        this.blockPerformImport = false
        return
      }

      this.toggleLoader(true)
      this.stopTrackProgress()

      const params = {
        kind,
        allow_create: !!Number(allow_create),
        allow_update: !!Number(allow_update),
        allow_delete: !!Number(allow_delete),
        search_by_title: !!Number(search_by_title),
        group_divider,
        thousandth_divider,
        master_id: this.data.masterId,
        items,
      }

      const response = await axios.post(`${gon.locale_path}/platform/import_excel`, params)
      this.startTrackProgress(response && response.data.import_id)
    } catch(e) {
      this.blockPerformImport = false
      this.toggleLoader(false)
      console.error(e)
      let message = e.message
      if (isAxiosNetworkError(e)) message = window.I18n.t('errors.network_error')
      if (isAxiosServerError(e)) message = window.I18n.t('errors.unexpected_error')
      this.showAlert(message, { error: true })
    }
  }

  startTrackProgress(import_id) {
    this.progressUpdateIntervalId = setInterval(() => this.trackProgress(import_id), 1000, import_id)
  }

  stopTrackProgress() {
    clearInterval(this.progressUpdateIntervalId)
    this.progressErrorsCount = 0
  }

  trackProgress = async (import_id) => {
    try {
      const response = await axios.get(`${gon.locale_path}/platform/import_excel/progress`, { params: { import_id }})
      const data = response.data

      this.hideAlert()

      switch (data.status) {
        case 'pending':
          this.updateProgress(data.progress)
          break
        case 'success':
          this.updateProgress(data.progress)
          this.finishImport(data.redirect_url)
          break
        case 'error':
          console.error(data)
          this.renderFinalErrors(data)
          break
        default:
          console.warn('Empty response', data)
      }
    } catch(e) {
      if (isAxiosNetworkError(e)) return this.showAlert(window.I18n.t('errors.network_error'), { warning: true })
      this.progressErrorsCount += 1
      console.error(e)

      // Better not to inform user that error happened, because he
      // will close import and try again, but error could be temporary, like server reboot,
      // or internal error that will be resolved by us soon.
      // this.showAlert(e.message, { warning: true })

      // Probably something went completely wrong, stop tracking
      if (this.progressErrorsCount > this.MAX_PROGRESS_ERRORS_COUNT) {
        this.renderFinalErrors({error: { general: [e.message] }})
      }
    }
  }

  renderFinalErrors(data) {
    this.stopTrackProgress()

    const { error, success_items_count, total_items_count } = data
    const general = error.general || []
    const rows = error.rows || []

    if (total_items_count) {
      general.push(window.I18n.t('excel_import_widget.imported_items_stats', {
        total_items: total_items_count,
        success_items: success_items_count
      }))
    }

    const generalErrors = general.map(message => {
      return `<div class='alert alert-danger'>${message}</div>`
    }).join('')

    const rowsErrors = rows.map(row => {
      return `<div class='error-row'>
        <div class='item'>${Object.values(row.item).join(', ')}</div>
        <div class='message'>${row.message}</div>
      </div>`
    }).join('')

    this.$errors.find('.error-messages').append(generalErrors)
    if (rowsErrors.length) this.$errors.find('.error-messages').append(`<div class='well'>${rowsErrors}</div>`)

    this.$modal.find('.modal-title').text(window.I18n.t('excel_import_widget.import_errors_title'))
    this.$errors.find('.alert').show()
    this.$loader.hide()
    this.$container.hide()
    this.$errors.show()

    // When import finished with error force reload page on close modal,
    // because some items could be successfully imported, and user should see it.
    this.$modal.on('hide.bs.modal', () => window.location.reload())
  }

  updateProgress(progress) {
    const percent = `${progress}%`

    this.$loader.find('.progress-bar').css('width', percent)
    this.$loader.find('.progress-hint').text(percent)
  }

  finishImport(redirect_url) {
    this.stopTrackProgress()
    // Sometimes we should redirect to provided url,
    // for example in document import will redirect to same document,
    // but with recalculate params, see doc.js
    setTimeout(() => {
      if (redirect_url) {
        window.location = redirect_url
      } else {
        window.location.reload()
      }
    }, 3000)
  }

  // Convert nested array table into array of hashes,
  // with all matched columns.
  // Examples:
  // [{ product_title: '...', product_code: '100' }, {}....]
  prepareItemsToImport(params) {
    const startRow = Number(this.$startRow.val()) - 1

    return this.table.slice(startRow).map(row => {
      return row.reduce((acc, cell, index) => {
        const column = this.matchedColumns[index]
        if (!column) return acc
        acc[column] = cell
        return acc
      }, {})
    })
  }

  columnSelectTemplate(index) {
    return (`
      <div class='column-select-container'>
        <select class='column-select' name='column_${index}' data-index='${index}'>
          <option value=''>${window.I18n.t('excel_import_widget.select_column')}</option>
          ${this.columns.map(col => `<option value='${col.key}'>${col.title}</option>`).join('')}
        </select>
      </div>
    `)
  }

  htmlTableHead() {
    return (`
      <tr>
        <th></th>
        ${range(this.table[0].length).map(index => `<th>${this.columnSelectTemplate(index)}</th>`).join('')}
      </tr>
    `)
  }

  htmlTableBody() {
    // No sense to display all rows
    return this.table.slice(0, this.MAX_ROWS_TO_DISPLAY).map((row, index) => {
      const removeButton = (`
        <button role='button' data-index='${index}' class='remove-row-btn'>
          <i class='fas fa-times'></i>
        </button>
      `)

      return (
        `
          <tr>
            <td>${removeButton}</td>
            ${row.map(cell => `<td><div class='cell-content'>${this.escapeHTML(cell)}</div></td>`).join('')}
          </tr>
        `
      )
    }).join('')
  }

  escapeHTML(html) {
    return toString(html)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
  }

  hideAlert() {
    this.$alert.hide()
  }

  showAlert(message, { error = false, warning = false } = {}) {
    if (error) {
      this.$alert.addClass('alert-danger')
      this.$alert.removeClass('alert-success alert-warning')
      console.error(message)
    } else if (warning) {
      this.$alert.addClass('alert-warning')
      this.$alert.removeClass('alert-danger alert-success')
    } else {
      this.$alert.addClass('alert-success')
      this.$alert.removeClass('alert-danger alert-warning')
    }
    this.$alert.show()
    this.$alert.html(message || window.I18n.t('errors.unexpected_error'))
  }

  handleRowRemove(e) {
    if (this.lock) return
    this.lock = true
    e.preventDefault()
    const index = Number($(e.currentTarget).data('index'))
    this.table.splice(index, 1)
    // Re-render whole table because we display only first 100 rows
    // and if rows more then new should be added.
    this.renderTable()
    if (index === 0 && !this.userManuallySelectedColumn) this.autoMatchColumns()
    this.lock = false
  }

  // User manually matched one of the columns in table
  handleColumnSelect(e) {
    this.userManuallySelectedColumn = true
    const index = $(e.target).data('index')
    this.matchedColumns[index] = $(e.target).val()
  }

  // Import from buffer require browser permission,
  // so if user declined it won't work without any visible error messages (console will display error).
  async handleBufferImport(e) {
    e.preventDefault()

    if (!navigator.clipboard) {
      console.warn('Clipboard not supported')
      return
    }

    const data = await navigator.clipboard.readText()
    this.displayPreview(data)
  }

  handleFileDrop(e) {
    e.preventDefault()

    const file = e.originalEvent.dataTransfer.files[0]
    this.processFile(file)
  }

  handleFileUpload(event) {
    const file = event.target.files[0]
    this.processFile(file)
  }

  // Here we try to match imported columns from excel with our columns by regex.
  // This will be skipped if user already matched manually at least one column.
  autoMatchColumns() {
    return // NOTE: Disable it for now, I think better to force user select all columns manually,
    // otherwise he will think that he shouldn't even verify automath.
    if (this.userManuallySelectedColumn || !this.table.length) return

    this.$table.find('.column-select').each((_, el) => {
      const $select = $(el)
      const index = $select.data('index')
      const name = this.table[0][index]
      const column = this.columns.find(col => {
        return !this.matchedColumns.includes(col.key) &&
          col.regex && col.regex.test(name)
      })
      if (!column) return
      this.matchedColumns[index] = column.key
      $select.val(column.key)
    })
  }

  setStartRow() {
    const start = Number(this.$startRow.val() || 0)
    this.$table.find('tr.start-row').removeClass('start-row')
    const startRow = this.$table.find('tr')[start]

    if (!startRow) return

    $(startRow).addClass('start-row')
  }

  processFile(file) {
    var reader = new FileReader()
    reader.onload = (e) => {
      /* reader.readAsArrayBuffer(file) -> data will be an ArrayBuffer */
      this.displayPreview(e.target.result)
    }
    reader.readAsArrayBuffer(file)
  }

  // Return two dimension array, like
  // [['Код', 'Название', 'Цена'], ['100', 'Pepsi', '10.0'], ['200', 'Ice cream', '20.0']]
  convertExcelDataToJson(data) {
    const excel = xlsx.read(data, { type: 'string', raw: true })
    const sheet = excel.Sheets[excel.SheetNames[0]]
    return xlsx.utils.sheet_to_json(sheet, { header: 1, blankrows: false, defval: '' })
  }

  toggleLoader(show) {
    this.$container.toggle(!show)
    this.$loader.toggle(show)
  }

  applySelectedTemplate(e) {
    const template = this.importTemplates.find(t => t.id === $(e.target).val())
    if (!template) return

    this.$importSettings.find('.columns-input').val(template.columns)
    this.$importSettings.find('.start-row-input').val(template.start_row)
    this.$importSettings.find('.group-divider-input').val(template.group_divider)
    this.$importSettings.find('.thousandth-divider-input').val(template.thousandth_divider)
    this.$importSettings.find('.allow-create-checkbox').prop('checked', template.allow_create)
    this.$importSettings.find('.allow-update-checkbox').prop('checked', template.allow_update)
    this.$importSettings.find('.allow-destroy-checkbox').prop('checked', template.allow_delete)
    this.$importSettings.find('.search-by-title-checkbox').prop('checked', template.search_by_title)

    // User may import table with columns count less than previosly saved,
    // we should remove exceeded columns
    const columnsCount = this.tableColumnsCount()

    this.matchedColumns = JSON.parse(template.columns || '[]').slice(0, columnsCount)

    this.$table.find('.column-select').each((_, el) => {
      const $select = $(el)
      const index = $select.data('index')
      $select.val(this.matchedColumns[index] || '')
    })
  }

  async handleUpdateTemplate(e) {
    e.preventDefault()

    try {
      this.hideAlert()
      this.$importSettings.find('.columns-input').val(JSON.stringify(this.matchedColumns))
      const params = this.importTemplateParams()
      if (!params.id) return this.showAlert(window.I18n.t('excel_import_widget.template_not_selected'), { error: true })
      const url = `${gon.locale_path}/platform/settings/import_templates/${params.id}.json`
      const response = await axios.put(url, { import_template: params })
      const data = response.data
      if (data.error) return this.showAlert(data.error)
      this.showAlert(window.I18n.t('excel_import_widget.template_update_success'))
    } catch (e) {
      console.error(e)

      let message = window.I18n.t('errors.unexpected_error')
      if (isAxiosNetworkError(e)) message = window.I18n.t('errors.network_error')
      if (isAxiosServerError(e)) message = window.I18n.t('errors.unexpected_error')
      this.showAlert(message, { error: true })
    }
  }

  // New template will be created with current selected settings.
  // Import template should be submitted with additional params, so
  // here we add custom logic, and because select has custom_create === true, it won't send double submit.
  onCreateImportTemplate(e) {
    e.preventDefault()

    // Fetch params from create modal form (actually just title)
    let params = formToObject(this.$importTemplateModal.find('form'), 'import_template').import_template
    // Add all params from import settings settings form, except ID,
    // So we create import template with title from create form and take all rest params from current settings form.
    params = { import_template: { ...params, ...omit(this.importTemplateParams(), ['id']) }}

    // onSubmitCreateOption is async
    onSubmitCreateOption(e, this.$importTemplateSelect, this.$importTemplateModal, {
      label: 'title',
      value: 'id',
      params,
      callback: this.afterCreateImportTemplate.bind(this)
    })
  }

  afterCreateImportTemplate(response) {
    this.importTemplates.push(response)
    this.showAlert(window.I18n.t('excel_import_widget.template_update_success'))
  }

  importTemplateParams() {
    return formToObject(this.$importSettings.find('form'), 'import_template').import_template
  }

  tableColumnsCount() {
    if (!this.table || !this.table.length) return 0

    return this.table[0].length || 0
  }
}

export default ImportExcelWidget
