import BaseWidget from './base_widget'
import axios from 'axios'
import { connectWidgets } from 'main/widgets/helpers'
import { errorMessage } from 'services/errors'
import ProductModal from 'main/document/product_modal'

// Widget for product page and product modal.
// When product-page data attribute passed then it's page.
// Product page not used for now, here just mockup
class ProductWidget extends BaseWidget {
  initialize() {
    this.isProductPage = this.el.data('product-page') || false
    this.isReadonly = this.el.data('readonly')

    if (this.isProductPage) {
      this.bindProductPageEvents()
    } else {
      this.passControlToProductModal()
    }
  }

  bindProductPageEvents() {
    // keep
  }

  passControlToProductModal() {
    new ProductModal(this.el, null, {
      onCreate: this.afterSaveProductModal.bind(this), onUpdate: this.afterSaveProductModal.bind(this)
    })
  }

  afterSaveProductModal(data) {
    if (!data.id) return

    window.location = `${gon.locale_path}/platform/products/${data.id}`
  }
}

export default ProductWidget
